import * as React from "react"

import Layout from "../components/layout/layout"
import CandidateContactSection from "../components/sections/candidate-contact-section"
import Seo from "../components/seo"

const SubmitResumePage = () => (
  <Layout>
    <Seo title="Submit Resume" />
    <CandidateContactSection />
  </Layout>
)

export default SubmitResumePage
